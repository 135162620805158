<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600">{{ orderData.customer }} 应付对账单</p>
    <p style="width: 300px; float: left;">流水号：{{ orderData.record_no }}</p>
    <p v-if="orderData.currency === 'CNY'" style="width: 200px; float: right;">币种：{{ orderData.currency }}</p>
    <p v-else style="width: 200px; float: right;">汇率：{{ orderData.balanceExchangeRate }} 币种：{{ orderData.currency }}</p>
    <div>
      <table>
        <tbody>
          <tr>
            <th>接单日期</th>
            <th>工作编号</th>
            <th>应付费用</th>
            <th>合计</th>
          </tr>
          <tr v-for="item in orderList" :key="item.biz_num">
            <td>{{ moment(item.record_item).format('YYYY/MM/DD') }}</td>
            <td>{{ item.biz_num }}</td>
            <td>
              <table class="table2">
                <tbody>
                  <tr v-for="fees in item.pay_fees" :key="fees.id">
                    <td class="w200">{{ fees.fee_name }}</td>
                    <td class="w200 moneyStyle">{{ moneyFormatter(fees.amount) }}</td>
                    <td class="w200">{{ fees.pay_target }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="moneyStyle">{{ moneyFormatter(item.pay_total) }}</td>
          </tr>
          <tr>
            <td colspan="2">合计：</td>
            <td>
              <table class="table2">
                <tbody>
                  <tr>
                    <td class="w200 moneyStyle">{{ moneyFormatter(orderData.pay_total) }}<br></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="moneyStyle">{{ moneyFormatter(orderData.pay_total) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'FmsPayStatementBill',
  components: {},
  props: {
    currentData: {
      type: Object,
      default: null
    },
    balanceData: {
      type: Array,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: []
    }
  },
  methods: {
    moment,
    initData() {
      this.orderData = this.currentData
      this.orderList = this.balanceData
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.moneyStyle {
  text-align: right;
}
.title{
  width: 100%;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 90%; margin: 0 auto; min-width:1000px;padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse; text-align: center; height: 100%; }
table td, th { border: 1px solid #000; }
.w50 { width:50px }
.w100 { width:100px }
.w200 { width:200px }
.table2 { font-size: 12px; }
.table2 tr td:nth-child(1) { border-left: 0; }
.table2 tr td:nth-last-child(1) { border-right: 0; }
.table2 tr:nth-child(1) td{ border-top: 0; }
.table2 tr:nth-last-child(1) td{ border-bottom: 0; }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
